<template>
    <td scope="row">1</td>
    <td>
        <div class="">
            <input v-model="form.first_name" type="text" class="form-control" id="user_fname" placeholder="Enter first name" :class="formControl(v$.form.first_name)" :disabled="disabled">
            <div v-if="v$.form.first_name.$error" class="invalid-feedback">
                <span>{{ errorMessage(v$.form.first_name.$errors[0].$validator, 'First name') }}</span>
            </div>
        </div>
    </td>
    <td>
        <div class="">
            <input v-model="form.last_name" type="text" class="form-control" id="user_lname" placeholder="Enter last name" :class="formControl(v$.form.last_name)" :disabled="disabled">
            <div v-if="v$.form.last_name.$error" class="invalid-feedback">
                <span>{{ errorMessage(v$.form.last_name.$errors[0].$validator, 'Last name') }}</span>
            </div>
        </div>
    </td>
    <td>
        <div class="">
            <input v-model="form.email" type="email" class="form-control" id="user_email" placeholder="Enter email address" :class="formControl(v$.form.email)" :disabled="disabled">
            <div v-if="v$.form.email.$error" class="invalid-feedback">
                <span>{{ errorMessage(v$.form.email.$errors[0].$validator, 'Email') }}</span>
            </div>
        </div>
    </td>
</template>

<script>
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Object,
            default() {
                return {
                    first_name: null,
                    last_name: null,
                    email: null,
                };
            }
        }
    },
    data() {
        const value = this.modelValue;
        return {
            form: {
                first_name: value['first_name'],
                last_name: value['last_name'],
                email: value['email']
            }
        };
    },
    emits: ['update:modelValue'],
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    validations () {
        return {
            form: {
                first_name: { required },
                last_name: { required },
                email: { required, email }
            }
        };
    },
    methods: {
        async confirm() {
            const result = await this.v$.$validate();
            if(!result) return false;
            this.$emit('update:modelValue', this.form);
            return true;
        },
        errorMessage(type, name) {
            switch(type) {
                case 'email': return `${name} is not a valid email address`;
                case 'required': return `${name} is required`;
            }
        },
        formControl(form) {
            return {
                'form-control': true,
                'is-invalid': form.$dirty && form.$invalid,
                'is-valid': form.$dirty && !form.$invalid
            };
        },
    }
})
</script>
