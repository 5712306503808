<template>
    <section class="invite-members-section">
        <div class="container my-container">
            <div class="invite-members-content">
                <div class="row no-gutters">
                    <div class="col-sm-12">
                        <h4>Invite Members</h4>
                    </div>

                    <div class="container-text">
                        <h5>A good day to invite friends.</h5>
                        <p>Please take a moment,<br>
                            pray and ask the Holy Spirit to guide you as you invite new members, <br>
                            and as we keep the integrity of our organization.
                        </p>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table table-borderless" id='invite_table'>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in lists" :key="index">
                                <ListItem :ref="`item-${index}`" v-model="lists[index]" :disabled="loading" />
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-add-row" @click="addRow">Add More Rows</button>
                    </div>
                </div>

                <div class="d-grid gap-2">
                    <!-- <button type="button" class="btn btn-submit-confirm" data-bs-toggle="modal" data-bs-target="#modal_invite" >Submit</button> -->
                    <button type="button" class="btn btn-submit-confirm" @click="submit" :disabled="loading">Submit</button>
                </div>
            </div>
        </div>
    </section>
    <ModalInviteConfirmation :lists="existingEmails" @submit="createRequest" :disabled="availableLists.length === 0" :loading="loading" />
    <ModalInviteSuccess />
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import ModalInviteConfirmation from '../components/modals.component/modal-invite-existing-users.vue';
import ListItem from '../components/invite.components/ListItem.vue';
import ModalInviteSuccess from '../components/modals.component/modal-invite-success.vue';

export default defineComponent({
    data() {
        return {
            loading: false,
            lists: [
                { first_name: null, last_name: null, email: null }
            ],
            existingEmails: [],
            availableLists: []
        };
    },
    methods: {
        addRow() {
            this.lists.push({ first_name: null, last_name: null, email: null });
        },
        async submit() {
            let completed = true;
            for(let item in this.lists) {
                const success = await this.$refs[`item-${item}`].confirm();
                if(!success) completed = false;
            }
            if(completed) {
                try {
                    this.loading = true;
                    const emails = await this.findEmails({
                        query: {
                            $paginate: false,
                            email: { $in: this.lists.map(i => i.email) }
                        }
                    }).then(result => result.map(i => i.email));

                    this.existingEmails = this.lists.filter(i => emails.indexOf(i.email) !== -1);
                    this.availableLists = this.lists.filter(i => emails.indexOf(i.email) === -1);
                    
                    if(emails.length !== 0) {
                        window['$']('#modal_invite').modal('show');
                    } else {
                        await this.createRequest();
                    }
                } catch(err) {
                    this.$toast(err.message, {
                        duration: 3000,
                        styles:  {
                            fontSize: '11pt',
                            backgroundColor: '#ce4f4f'
                        },
                        slot: '<i class="fa fa-exclamation"></i>'
                    });
                } finally {
                    this.loading = false;
                }
            }
        },
        async createRequest(e) {
            try {
                if(e) this.loading = true;
                for(let item of this.availableLists) {
                    await this.createInviteRequest(item);
                }
                window['$']('#modal_invite').modal('hide');
                window['$']('#modal_success').modal('show');
                this.lists = [];
                setTimeout(() => {
                    this.addRow();
                }, 500);
            } catch(err) {
                this.$toast(err.message, {
                    duration: 3000,
                    styles:  {
                        fontSize: '11pt',
                        backgroundColor: '#ce4f4f'
                    },
                    slot: '<i class="fa fa-exclamation"></i>'
                });
            } finally {
                if(e) this.loading = false;
            }
        },
        ...mapActions('users', { findEmails: 'find' }),
        ...mapActions('user-invites', { createInviteRequest: 'create' })
    },
    components: {
        ModalInviteConfirmation,
        ModalInviteSuccess,
        ListItem
    }
})
</script>
